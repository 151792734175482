.ant-menu-active {
  color: $primary;
}

.ant-menu-light
  > .ant-menu
  > .ant-menu-submenu-selected
  > .ant-menu-submenu-title::after {
  color: $primary;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-item-selected {
  // background-color: $primary-bg-subtle;
  color: $primary;
}

.ant-menu-submenu-title:hover,
.ant-menu-submenu-title-active {
  color: $primary;
}

.ant-menu-submenu .ant-menu-item:hover {
  // background-color: $primary-bg-subtle;
  color: $primary;
}

.ant-menu-submenu .ant-menu-item-selected {
  // background-color: $primary-bg-subtle;
  color: $primary;
}

.ant-spin-dot {
  .ant-spin-dot-item {
    background-color: #5c2a69;
  }
}

.ant-statistic-content-value-int {
  color: $primary;
}
